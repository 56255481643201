/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyAzFAQKt_yKJ9yvur_0UhQEJa-vHT5ayH4",
  "appId": "1:680579604638:web:1988aac9e75fa241bf5b8f",
  "authDomain": "schooldog-i-decaturcity-al.firebaseapp.com",
  "measurementId": "G-B19FDGVEXZ",
  "messagingSenderId": "680579604638",
  "project": "schooldog-i-decaturcity-al",
  "projectId": "schooldog-i-decaturcity-al",
  "showGaBranding": false,
  "storageBucket": "schooldog-i-decaturcity-al.appspot.com"
}
